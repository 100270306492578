<template>
  <div class="setinfo">
    <van-nav-bar
      title="个人信息"
      left-arrow
      :right-text="iswx ? '读取微信资料': ''"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="edinfo">
      <van-form @submit="onSubmit">
        <van-cell class="avatar" title="头像">
          <template #right-icon>
            <van-uploader
              max-count="1"
              :after-read="afterRead"
              :before-read="beforeRead"
              v-model="fileList"
              preview-size="80px"
            >
              <template #preview-cover>
                <van-image
                  v-if="fileList[0].content && fileList[0].content.length > 10 ? true : false"
                  width="80"
                  height="80"
                  round
                  :src="fileList[0].content"
                  lazy-load
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </template>
            </van-uploader>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
        <van-field
          v-model="nickname"
          required
          label="昵称"
          placeholder="请输入昵称"
          :rules="[{ required: true, message: '请填写用户昵称' }]"
        />
        <van-field
          v-model="mobile"
          required
          label="手机号"
          placeholder="请输入手机号"
          is-link
          readonly
          @click="changeMobile"
        >
          <template #button>
            更换手机号
          </template>
        </van-field>
        <van-field
          v-model="realname"
          label="姓名"
          placeholder="请输入姓名"
        />
        <van-field
          v-model="weixin"
          label="微信号"
          placeholder="请输入微信号"
        />
        <van-field
          v-model="bothirday"
          label="出生日期"
          placeholder="请输入出生日期"
          readonly
          clickable
          @click="showCalendar = true"
        />
        <van-field
          v-if="qunhao > 0"
          v-model="qunhao"
          label="素材群号"
          readonly
          clickable
          @click="copay(qunhao)"
        >
          <template #button>
            <van-button size="mini" plain native-type="button">复制</van-button>
          </template>
        </van-field>
        <van-field
          v-model="city"
          label="所在城市"
          readonly
          clickable
          placeholder="点击选择省市区"
          @click="showArea = true"
          style="padding-bottom: 20px;"
        />
        <div style="margin: 16px;">
          <van-button round block type="danger" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="clear_catch">
      <van-cell @click="clearCatch" title="清除缓存" is-link></van-cell>
    </div>
    <van-popup v-model="showArea" position="bottom">
      <van-area
        v-model="citycode"
        columns-num="3"
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
    <van-popup v-model="showCalendar" position="bottom">
      <van-datetime-picker
        v-model="bothirdayFormDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirmTime"
        @cancel="showCalendar = false"
      />
    </van-popup>
  </div>
</template>
<script>
import areaList from "@/components/area.js";
export default {
  name: 'SetInfo',
  data(){
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      fileList: [
        {
          content: ""
        }
      ], // 上传文件
      avatar: "", // 用户头像
      nickname: "", // 昵称
      mobile: "", // 手机号
      realname: "", // 真实姓名
      weixin: "", // 微信号
      qunhao: 0, // 素材群号
      bothirday: "", // 生日
      bothirdayFormDate: new Date(), // 生日
      city: "", // 所在城市
      citycode: "", // 所在城市
      showArea: false, // 地区弹框默认隐藏
      areaList: areaList, // 地区列表
      showCalendar: false, // 日历弹框默认隐藏
      minDate: new Date(new Date().getFullYear()-50, 0, 1),
      maxDate: new Date(new Date().getFullYear(), 12, 31),
      userinfo: {}, // 用户信息
      isLogin: localStorage.getItem("DK_ISLOGIN")?localStorage.getItem("DK_ISLOGIN"):false,
      shareid: 0,
      merchid: 0,
      iswx: false, // 是否在微信中
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "getLocation",
          "openLocation"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5", // 必填，签名
        openTagList: ['wx-open-launch-weapp','wx-open-launch-app']
      },
    }
  },
  mounted(){
    this.init();
    if (window.isWeixin()) {
      this.iswx = true;
    }else {
      this.iswx = false;
    }
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "个人信息");

    if (window.isWeixin()) {
      if (this.$route.query.code) {
        this.getwxlog(this.$route.query.code);
      }
    }
  },
  methods:{
    init(){
      this.getinfo();
      if (window.isWeixin()) {
        if(this.$route.query.shareid && this.$route.query.shareid==69041) {
          this.peizhiWxConfig();
        }
      }
    },

    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;
      console.log(_that.iswx);
      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function(response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
          setTimeout(() => {
            _that.onShare();
          }, 100)
        })
        .catch(function(error) {
          console.log(error);
          console.log("ddd");
          window.getWx = _that.getWxConfig; // 微信签名过期后调用 外部JS
        });
    },
    /* 设置分享信息 */
    onShare() {
      let _that = null;
      _that = this;
      const mid = localStorage.getItem("DK_MEMBERID"); // 分享者id
      let title = "大咖货源"; // 分享标题
      let desc = "大咖货源"; // 分享简介
      let img = ""; // 分享图片地址
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_merchNme",
          _that.$qs.stringify({
            id: _that.merchid
          })
        )
        .then(res => {
          if (res.data.merchname) {
            title = res.data.merchname;
            desc = res.data.merchname;
          }
          if (res.data.logo) {
            img = res.data.logo;
          }

          window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            window.wx.updateAppMessageShareData({ 
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: _that.$store.state.domain3 + "?shareid=" + mid + "&merchid=" + _that.merchid, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              type: "link",
              success: function() {
                console.log("分享设置成功");
              }
            })
          });
        })
    },

    // 获取用户信息
    getinfo(){
      let _that = null;
      _that = this;
      const openid = localStorage.getItem("DK_OPENID")?localStorage.getItem("DK_OPENID"):"" // 是否获取openid,如果获取就是公众号内打开，否则是页面打开
      const jwtStr = localStorage.getItem("DK_UID")?localStorage.getItem("DK_UID"):"" // 公钥
      _that.$axios.defaults.headers.common["Authorization"] = jwtStr;
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + 'web/user/getinfor')
        .then(function(response){
          console.log(response);
          if (_that.isLogin && response.data) {
            _that.userinfo = response.data;

            if (response.data.avatar) {
              _that.fileList[0].content = response.data.avatar?response.data.avatar:"";
            }else{
              _that.fileList = [];
            }
            
            _that.avatar = response.data.avatar ? response.data.avatar : ""; // 头像
            _that.nickname = response.data.nickname?response.data.nickname:""; // 昵称
            _that.mobile = response.data.mobile?response.data.mobile:""; // 手机号
            // 生日信息
            const birthyear = response.data.birthyear?response.data.birthyear:""; // 生日年
            const birthmonth = response.data.birthmonth?response.data.birthmonth:""; // 生日月
            const birthday = response.data.birthday?response.data.birthday:""; // 生日天
            if (birthyear && birthmonth && birthday) {
              _that.bothirday = birthyear + '-' + birthmonth + '-' + birthday;
              _that.bothirdayFormDate = new Date(_that.bothirday)
            }
            _that.realname = response.data.realname?response.data.realname:"";
            _that.weixin = response.data.weixin?response.data.weixin:"";

            let str = "";
            const province = response.data.province?response.data.province:"";
            const city = response.data.city?response.data.city:"";
            const county = response.data.area?response.data.area:"";
            if (province) { str += province }
            if (city) { str += ' ' + city }
            if (county) { str += ' ' + county }
            _that.city = str; // 所在城市
            _that.citycode = response.data.area_code?response.data.area_code:"";
            _that.qunhao = response.data.qunhao > 0 ? response.data.qunhao : 0;
          }
        })
        .catch(function(error){
          console.log(error);
        })
    },
    onClickLeft(){
      this.$router.go(-1);
    },
    // 读取微信资料
    onClickRight() {
      const openid = localStorage.getItem("DK_OPENID");
      let _that = null;
      _that = this;
      if (this.iswx) {
        // 有openID
        if (openid) {
          _that.$dialog.alert({
            title: "提示",
            message: '确认使用微信昵称头像吗？使用微信资料保存后才生效',
            showCancelButton: true,
            beforeClose: function (action, done) {
              if (action === 'confirm') {
                _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
                _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
                _that.$axios
                  .post(
                    _that.$store.state.domain + "web/user/reg_openid",
                    _that.$qs.stringify({
                      openid: openid
                    })
                  )
                  .then(res => {
                    console.log(res);
                    if (res.data.code == 100000) {
                      console.log(_that.shareid, '_that.shareid');
                      _that.getnewwxinfo(openid);
                    }else {
                      _that.$toast(res.data.msg ? res.data.msg : "该账号绑定微信不是此微信");
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  })
              }
              done();
            },
          });
        }
      }
    },


    getnewwxinfo() {
      if (window.isWeixin()) {
        const wxAuthUrl2 = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        window.location.href = wxAuthUrl2;
      }
    },
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          console.log(response);
          if (response.data) {
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                console.log(res);
                if (res.data.openid) {
                  _that.setopenid(res.data.openid, _that.userinfo.id); // 保存openID
                  _that.avatar = res.data.headimgurl // 用户头像
                  _that.fileList[0].content = res.data.headimgurl // 用户头像
                  _that.nickname = res.data.nickname // 昵称
                }
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    // 保存openID和用户id的关系
    setopenid(openid, uid) {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/pay/setopenid",
          _that.$qs.stringify({
            mid: parseInt(uid),
            openid: openid,
            type: "daka"
          })
        )
        .then(res => {
          console.log(res);
          _that.$router.replace({name: "SetInfo", query: {shareid: _that.shareid, merchid: _that.merchid} });
        })
        .catch(err => {
          console.log(err);
          _that.$router.replace({name: "SetInfo", query: {shareid: _that.shareid, merchid: _that.merchid} });
        })
    },

    // 地区弹框选择确定按钮
    onConfirm(values){
      console.log(values);
      this.city = values.map((item) => item.name).join(' ');
      this.citycode = values[values.length-1].code;
      this.showArea = false;
    },
    // 日历弹框选择确定按钮
    onConfirmTime(date){
      const years = date.getFullYear();
      let months = date.getMonth() + 1;
      let days = date.getDate();
      if (parseInt(months) < 10) {
        months = '0' + months;
      }
      if (parseInt(days) < 10) {
        days = '0' + days;
      }
      this.bothirday = years + '-' + months + '-' + days;
      this.showCalendar = false;
    },
    // 读取文件完成之后
    afterRead(file){
      console.log(file);
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/member/');

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      _that.$axios.post(
        _that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data && response.data.code == 100000) {
          _that.avatar = response.data.img_url
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 读取文件完成之前
    beforeRead(file){
      return true;
    },
    // 点击提交按钮
    onSubmit(){
      console.log('onSubmit');
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios.post(
          _that.$store.state.domain + 'web/user/set_info',
          _that.$qs.stringify({
            uid: _that.userinfo.id, // 用户id
            avatar:_that.avatar, // 用户头像
            nickname: _that.nickname, // 昵称
            mobile: _that.mobile, // 手机号
            realname: _that.realname, // 真实姓名
            weixin: _that.weixin, // 微信号
            bothirday: _that.bothirday, // 生日
            city: _that.city, // 所在城市
            citycode: _that.citycode // 城市编码
          })
        ).then(response => {
          console.log(response);
          if (response.data && response.data.code == 100000) {
            _that.$notify({
              type: 'success',
              message: response.data.msg?response.data.msg:'修改成功'
            })
            setTimeout(()=>{
              _that.$router.go(-1)
            },3000)
          }
        }).catch(error => {
          console.log(error);
        })
    },
    // 更换手机号
    changeMobile() {
      if (this.userinfo.mobile) {
        this.$router.push({ name: "ChangeMoble1", query: { shareid: this.shareid, merchid: this.merchid } });
      }else{
        this.$router.push({ name: "ChangeMoble2", query: { shareid: this.shareid, merchid: this.merchid } });
      }
    },

    // 复制素材群号
    copay(e) {
      console.log(e);
      let qunhao = null;
      qunhao = e;
      if (qunhao) {
        let _that = null;
        _that = this;
        _that.$copyText(qunhao).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }
    },
    
    clearCatch(){
      // localStorage.clear();
      localStorage.removeItem("DK_MERCHID");
      localStorage.removeItem("DK_SHAREID");
      localStorage.removeItem("DA-LANDPAGE-TITLE");
      localStorage.removeItem("searchText");
      localStorage.removeItem("DA-LANDPAGE-REFERRER");
      localStorage.removeItem("DK_STRID");
      localStorage.removeItem("ordersn");
      localStorage.removeItem("orderid");
      localStorage.removeItem("money");
      localStorage.removeItem("DK_DATA");
      localStorage.removeItem("DA-LANDPAGE");
      localStorage.removeItem("DK_WXINFO");
      localStorage.removeItem("DK_OPENID");
      localStorage.removeItem("DK_MEMBERID");
      localStorage.removeItem("DK_UID");
      localStorage.removeItem("DK_ISLOGIN");
      localStorage.setItem("home",1);
      setTimeout(() => {
        this.$router.go(-1);
      }, 500);
    }
  }
}
</script>
<style lang="less">
  .setinfo{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    background-color: #ededed;
    position: relative;
    z-index: 2;
    .van-nav-bar{
      .van-icon{
        color: #333;
      }
    }
    .edinfo{
      .van-form{
        .avatar{
          padding: 10px;
          overflow: hidden;
          .van-cell__title{
            width: 70px;
            margin-right: 12px;
            text-align: left;
            line-height: 80px;
          }
          .van-uploader{
            display: block;
            width: 100%;
            .van-uploader__input-wrapper{
              width: 100%;
            }
            .van-uploader__wrapper{
              float: right;
            }
            .van-uploader__preview,
            .van-uploader__upload{
              margin: 0;
            }
            .van-uploader__preview-image{
              border-radius: 50%;
            }
            .van-icon{
              height: 14px;
              line-height: 14px;
            }
          }
          .van-icon{
            line-height: 80px;
            margin-left: 5px;
          }
        }
      }
      
      .van-cell{
        padding: 10px 16px;
        .van-cell__title{
          width: 70px;
        }
        .van-cell__value{
          flex: 1;
        }
      }
    }
    .clear_catch {
      margin-top: 60px;
      .van-cell {
        .van-cell__title{
          flex: 1;
          text-align: left;
        }
      }
    }
  }
</style>
